/* Here go our custom mixins and functions */

// http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
// Set a rem font size with pixel fallback

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}


// Convert px to em, useful for responsive font-sizes and paddings/margins
// $baseFontSize: 16px;

@function em($target){
  @return ($target/$baseFontSize)+em;
}

@function rem($target){
  @return ($target/16)+rem;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

$mobile-small: 310px;
$pebble: 310px;
$mobile: 598px;
$stone: 598px;
$tablet: 775px;
$rock: 902px;
$tablet-large: 902px;
$mountain: 991px;
$desktop-sm: 991px;
$desktop: 1024px;
$desktop-hires: 1281px;
$desktop-fhires: 1565px;
$ipad: 768px;
$iphone5-landscape: 567px;
$iphone6-landscape: 736px;

@mixin at-least($device-width) {
  @media screen and (min-width: $device-width + 1) {
    @content
  }
}

@mixin until($device-width) {
  @media screen and (max-width: $device-width) {
    @content
  }
}

// clearfix hack

%clearfix,
.clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//.clearfix:after {
//    content: ".";
//    height: 0;
//    clear: both;
//    visibility: hidden;
//}
//.clearfix{ display:block; }
//.floatbox { overflow:hidden; }
#ie_clearing { display: none; }


p.zmi_clearfix_title { color: green; font-style: italic; }
hr.zmi_clearfix { color: green; }



// animation helper (multi browser support)

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

// Transitions
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}


// cross browser opacity

@mixin opacity($opacity) {
  opacity: $opacity !important;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// visually hidden

%visuallyhidden {
   margin: -1px;
   padding: 0;
   width: 1px;
   height: 1px;
   overflow: hidden;
   clip: rect(0 0 0 0);
   clip: rect(0, 0, 0, 0);
   position: absolute;
}




// https://gist.github.com/garyharan/957284
// box shadow

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin rotate($deg:20deg) {
   -moz-transform: rotate($deg);
   -ms-transform: rotate($deg);
   -o-transform: rotate($deg);
   -webkit-transform: rotate($deg);
   transform: rotate($deg);
}

@mixin text-field {
 display: inline-block;
  outline: none;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
}

@mixin button($color: $red, $text_color: $white) {
  display: inline-block;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font: 14px/100% Arial, Helvetica, sans-serif;
  padding: .5em 2em .55em;
  text-shadow: 0 1px 1px rgba(0,0,0,.3);
  @include rounded();
  @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));

  color: $text_color !important;
  font-weight: bold;
  border: solid 1px darken($color, 18%);
  background: $color;
  @include gradient(saturate($color, 15%), darken($color, 15%));

  &:hover {
    text-decoration: none;
    background: saturate($color, 10%);
    @include gradient(saturate($color, 5%), darken($color, 5%));
  }

  &:active {
    position: relative;
    top: 1px;
    color: saturate($color, 15%);
    @include gradient(saturate($color, 15%), lighten($color, 15%));
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin rounded-top($radius: 0.5em) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin rounded-bottom($radius: 0.5em) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

// niels local



@mixin flex-container($type: nowrap, $dir: column) {
   -ms-box-orient: horizontal;
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: -moz-flex;
   display: -webkit-flex;
   display: flex;
   display: flex;
   -webkit-flex-wrap: $type;
   flex-wrap: $type;
   flex-direction: $dir;
}


// opaque background colors
// @include alpha-background-color(rgba(black, 0.5), white);

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color !important;
  background-color: $color !important;
}


@mixin font-mdi($content){
    font-family: "Material Design Icons";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    text-decoration: inherit;
    content: $content;
}


